<template>
  <base-section id="bio">
    <v-container>
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <base-img
            :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
            :src="require(`@/assets/jeremy.jpg`)"
            color="grey lighten-1"
            flat
            tile
          />
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="Jeremy Jackson"
            space="0"
          />

          <base-title
            class="primary--text"
            title="COACH"
            tag="div"
          />

          <base-body>
            Jeremy was born in Buffalo, NY and grew up in Bradenton, Florida. Jeremy graduated from Manatee High School
            (Bradenton, Fl) where he played football, ran track and also participated in high school weightlifting
            (Bench Press and Clean & Jerk). Jeremy was a 3-time state qualifier in high school weightlifting at Manatee
            High School.
            <br>
            <br>
            Jeremy completed his studies to be a Physical Therapist Assistant at the State College of Florida before
            becoming a certified personal trainer, which he has been a certified personal trainer for since 2000.
            <br>
            <br>
            Jeremy began coaching Olympic-style weightlifting in 2013. Since then he has produced both state and
            national champions.
            <br>
            <br>
            “My goals are very athlete based. I have been blessed with the opportunity to coach athletes at the national level and the atmosphere sparked something in myself and my team and from that point on we’ve set our sights on that feeling every year! These accolades tell me that we are building a great program and headed in the right direction!”
            <br>
            <br>
            He currently competes as 73kg lifter as well. He is a national level athlete and has competed in a number of national competitions. Most notably winning a national meet the American Open Series in 2017.
            <br>
            <br>
            He has been the weightlifting coach for high school multiple athletes and multiple high schools in Orlando, FL since 2014.
          </base-body>

          <base-btn class="mb-12">
            Get Coaching
          </base-btn>

          <v-row>
            <v-col
              v-for="(text, i) in marketing"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import BaseBody from '@/components/base/Body'

  export default {
    name: 'SectionBio',
    components: { BaseBody },
    data: () => ({
      marketing: [
        'International Sports Science Association – CFT',
        'National Academy of Sport Medicine – CPT',
        'National Academy of Sport Medicine – Fitness Nutrition Specialist',
        'TRX – Functional Training Course Certified',
        'USAW – L2 Sports Performance',
        'CPR / AED Certified',
      ],
    }),
  }
</script>
